import React, { Component } from "react";
import {
	ConfigProvider,
	AdaptivityProvider,
	AppRoot,
	Appearance,
	Platform,
	Scheme,
	SplitLayout,
	PanelHeader,
	Snackbar,
	Avatar,
	WebviewType,
	ScreenSpinner,
} from "@vkontakte/vkui";
import { Icon16ErrorCircleFill, Icon16Done } from "@vkontakte/icons";

import {
	currentScheme,
	getPlatform,
	getQueryVariable,
	currentTab,
	currentPanel,
	inIframe,
} from "./utils";

import Navigation from "./Components/Navigation";
import ViewPages from "./Components/ViewPages";

if (getQueryVariable("iframe") === "true") {
	document.documentElement.classList.add("iframe");
	document.documentElement.classList.add("vkui");
}

export default class VTSite extends Component {
	constructor(props) {
		super(props);

		this.state = {
			panel: currentPanel(),
			snackbar: null,
			activeTab: currentTab(),
			activeModal: null,
			scheme: document.body.getAttribute("scheme"),
		};

		var originalFunc = this.setState;
		let temp = this;
		this.setState = function (t, onPush = true) {
			if (t.panel) {
				onPush && window.history.pushState(null, null, `${t.panel}`);
				//ReactGA.pageview(window.location.pathname + window.location.search);
			}
			originalFunc.apply(this, arguments);
			window.onpopstate = () => {
				let _ = window.location.pathname.replace("/", "");
				if (_ === "") _ = "home";
				temp.setState1({ panel: _ });
			};
		};
		this.setState1 = function () {
			originalFunc.apply(this, arguments);
		};

		new MutationObserver((MutationRecord) => {
			MutationRecord.forEach((mutation) => {
				this.setState({
					scheme:
						mutation.target.getAttribute("scheme") ||
						"bright_light",
				});
			});
		}).observe(document.body, {
			attributes: true,
			attributeFilter: ["scheme"],
		});
	}

	openSnackBar(text, error = false, duration = 2000, before = null) {
		if (this.state.snackbar) return;
		this.setState({
			snackbar: (
				<Snackbar
					duration={duration}
					onClose={() => this.setState({ snackbar: null })}
					before={
						before ? (
							before
						) : (
							<Avatar
								size={24}
								style={{ background: "var(--accent)" }}
							>
								{error ? (
									<Icon16ErrorCircleFill
										fill="#fff"
										width={24}
										height={24}
									/>
								) : (
									<Icon16Done
										fill="#fff"
										width={14}
										height={14}
									/>
								)}
							</Avatar>
						)
					}
				>
					{text}
				</Snackbar>
			),
		});
	}

	showSpinner() {
		this.setState({ popout: <ScreenSpinner /> });
	}

	hideSpinner() {
		this.setState({ popout: null });
	}

	render() {
		var isDesktop = getPlatform() === "computer";
		var hasHeader = getPlatform() === "computer";
		const hasHeaderPanel = getQueryVariable("type") !== "noHeader";
		const navigation = getQueryVariable("nav") !== "false";
		this.navigation = navigation;

		if (!hasHeaderPanel) {
			hasHeader = hasHeaderPanel;
		}

		if (inIframe()) {
			isDesktop = false;
		}

		document
			.querySelector("meta[name=theme-color]")
			.setAttribute(
				"content",
				currentScheme() === Scheme.SPACE_GRAY ? "#19191a" : "#ffffff",
			);

		return (
			<ConfigProvider
				appearance={Appearance.DARK}
				scheme={currentScheme()}
				platform={Platform.IOS}
				webviewType={WebviewType.INTERNAL}
			>
				<AdaptivityProvider>
					<AppRoot>
						<SplitLayout
							style={{ justifyContent: "center" }}
							header={
								hasHeader && <PanelHeader separator={false} />
							}
							popout={this.state.popoutAction}
						>
							<Navigation
								app={this}
								isDesktop={isDesktop}
								navigation={navigation}
								hasHeader={hasHeader}
							/>
							<ViewPages
								app={this}
								isDesktop={isDesktop}
								navigation={navigation}
								hasHeader={hasHeader}
								hasHeaderPanel={hasHeaderPanel}
							/>
						</SplitLayout>
					</AppRoot>
				</AdaptivityProvider>
			</ConfigProvider>
		);
	}

	//componentDidMount() {
	//	let scroll = document.querySelector('.VKNScroll > div:nth-child(3)');
	//	scroll.classList.add('VKNScrollWrap')
	//	scroll.firstChild.classList.add('VKNScrollBars');
	//
	//	scroll = document.querySelector('.VKNScroll > div:nth-child(2)');
	//	scroll.classList.add('VKNScrollWrap')
	//	scroll.firstChild.classList.add('VKNScrollBars');
	//}
}
