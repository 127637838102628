import {
	Panel,
	PanelHeader,
	Group,
	Cell,
	Epic,
	Tabbar,
	TabbarItem,
	SplitCol,
} from "@vkontakte/vkui";

import {
	Icon28DonateOutline,
	Icon28HomeOutline,
	Icon24InfoCircleOutline,
} from "@vkontakte/icons";

import React, { Component }  from 'react';

import { ReactComponent as VKNLogo } from "../Icons/VT_Fill_Logo.svg";

import { panels } from "../utils";


export default function Navigation({ app, isDesktop, navigation, hasHeader }) {
	let panel = app.state.panel;
	if (window.navigation === undefined) {
		window.navigation = navigation;
	} else {
		navigation = window.navigation;
	}

	if (!navigation) return <div></div>;
	if (isDesktop) {
		return (
			<SplitCol fixed width="280px" maxWidth="280px">
				<Panel>
					{hasHeader && <PanelHeader left={<VKNLogo />} />}
					<Group>
						<Group mode="plain">
							<Cell
								key={panels[0]}
								disabled={panels[0] === panel}
								style={
									panels[0] === panel
										? {
												backgroundColor:
													"var(--button_secondary_background)",
												borderRadius: 8,
										  }
										: {}
								}
								onClick={() => {
									app.setState({
										panel: panels[0],
										activeTab: "info",
									});
								}}
								before={
									<Icon28HomeOutline width={24} height={24} />
								}
							>
								Главная
							</Cell>
							<Cell
								key={panels[1]}
								disabled={panels[1] === panel}
								style={
									panels[1] === panel
										? {
												backgroundColor:
													"var(--button_secondary_background)",
												borderRadius: 8,
										  }
										: {}
								}
								onClick={() => {
									app.setState({
										panel: panels[1],
										activeTab: "info",
									});
								}}
								before={
									<Icon28DonateOutline
										width={24}
										height={24}
									/>
								}
							>
								Пожертвования
							</Cell>
						</Group>
						
						
						<Cell
							key={panels[5]}
							disabled={panels[5] === panel}
							style={
								panels[5] === panel
									? {
											backgroundColor:
												"var(--button_secondary_background)",
											borderRadius: 8,
									  }
									: {}
							}
							before={
								<Icon24InfoCircleOutline
									width={24}
									height={24}
								/>
							}
							onClick={() => {
								app.setState({ panel: panels[5] });
							}}
						>
							О ВТостерс
						</Cell>
						
					</Group>
				</Panel>
			</SplitCol>
		);
	} else {
		return (
			<SplitCol
				animate={false}
				spaced={isDesktop}
				width={isDesktop ? "660px" : "0px"}
				maxWidth={isDesktop ? "660px" : "0px"}
			>
				<Epic
					activeStory={panel}
					tabbar={
						<Tabbar>
							<TabbarItem
								selected={panels[0] === panel}
								text="Главная"
								onClick={() => {
									app.setState({
										panel: panels[0],
										activeTab: "info",
									});
								}}
							>
								<Icon28HomeOutline width={24} height={24} />
							</TabbarItem>
							<TabbarItem
								selected={panels[1] === panel}
								text="Пожертвования"
								onClick={() => {
									app.setState({
										panel: panels[1],
										activeTab: "info",
									});
								}}
							>
								<Icon28DonateOutline width={24} height={24} />
							</TabbarItem>
							
							
							<TabbarItem
								selected={panels[5] === panel}
								text="О ВТостерс"
								onClick={() => {
									app.setState({ panel: panels[5] });
								}}
							>
								<Icon24InfoCircleOutline
									width={24}
									height={24}
								/>
							</TabbarItem>
						</Tabbar>
					}
				/>
			</SplitCol>
		);
	}
}
