import {
	SplitCol,
	View,
	ModalRoot,
	Panel,
	PanelHeader,
} from "@vkontakte/vkui";

import "@vkontakte/vkui/dist/unstable.css";

import "@vkontakte/vkui/dist/vkui.css";

import React, { Component }  from 'react';

import PanelHome from "./PanelHome";
import PanelDonsite from "./PanelDonsite";
import PanelLinks from "./PanelLinks";
import PanelHelp from "./PanelHelp";
import PanelAbout from "./PanelAbout";
import { panels } from "../utils";

import { ReactComponent as VKNLogo } from "../Icons/VT_Fill_Logo.svg";





export default function ViewPages({ app, isDesktop, hasHeaderPanel }) {
	let panel = app.state.panel;

	

	const modal = (
		<ModalRoot activeModal={app.state.activeModal}>
			
			
			
			
			
		</ModalRoot>
	);

	return (
		<SplitCol
			animate={false}
			spaced={isDesktop}
			width={isDesktop ? "660px" : "100%"}
			maxWidth={isDesktop ? "660px" : "100%"}
		>
			<View activePanel={panel} modal={modal} popout={app.state.popout}>
				<Panel id={panels[0]}>
					{hasHeaderPanel && (
						<PanelHeader
							
						>
							{isDesktop ? "Главная" : <VKNLogo />}
						</PanelHeader>
					)}
					<PanelHome app={app} isDesktop={isDesktop} />
				</Panel>
				<Panel id={panels[1]}>
					{hasHeaderPanel && <PanelHeader>Пожертвования</PanelHeader>}
					<PanelDonsite app={app} isDesktop={isDesktop} />
				</Panel>
				
				<Panel id={panels[3]}>
					{hasHeaderPanel && <PanelHeader>Ссылки</PanelHeader>}
					<PanelLinks app={app} isDesktop={isDesktop} />
				</Panel>
				<Panel id={panels[4]}>
					<PanelHelp
						app={app}
						isDesktop={isDesktop}
						hasHeaderPanel={hasHeaderPanel}
					/>
				</Panel>
				<Panel id={panels[5]}>
					{hasHeaderPanel && <PanelHeader>О ВТостерс</PanelHeader>}
					<PanelAbout app={app} isDesktop={isDesktop} />
				</Panel>
				
			</View>
			{app.state.snackbar}
		</SplitCol>
	);
}
