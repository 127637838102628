import React, { Component } from "react";
import {
	Group,
	Cell,
	View,
	Panel,
	PanelHeader,
	PanelHeaderBack,
	Card,
	CardGrid,
	Div,
	Header,
	Text,
	Link,
} from "@vkontakte/vkui";

import {
	Icon28LaptopOutline,
	Icon28DownloadOutline,
	Icon28SettingsOutline,
	Icon28QuestionOutline,
	Icon28CheckShieldOutline,
} from "@vkontakte/icons";

import { getQueryVariable } from "../utils";

const help = {
	list: {},
	iversions: {
		name: "Различия версий",
		answers: [
			{
				header: "Сколько версий VK Next существует?",
				text: `У расширения есть 2 версии: <br/><br/> 1. script-версия - распространяется в формате userscript'а. Устанавливается с нашего сайта и функционирует через Tampermonkey. <br/><br/>2. store-версия - распространяется в формате самостоятельного расширения и устанавливается в браузер из каталогов расширений Chrome Web Store и Firefox Add-ons.`,
			},
			{
				header: "В чём отличия script- и store- версий VK Next?",
				text: `Script-версия быстрее получает обновления, так как обновления store-версии проходят модерацию в магазинах перед публикацией. Однако со store-версией сайт немного быстрее работает на слабых компьютерах, а её установка может показаться более простой.`,
			},
			{
				header: "Существует ли версия VK Next для Android и iOS?",
				text: `Нет. VK Next - расширение для браузера на компьютере.`,
			},
		],
	},
	install: {
		name: "Установка и обновления",
		answers: [
			{
				header: "Почему нужно устанавливать script-версию VK Next именно через Tampermonkey?",
				text: `Каждый установщик скриптов имеет свои особенности, которые порой ломают некоторый функционал VK Next. Например, обновления, загрузку тем или какие-либо другие функции. Поэтому мы создаём script-версию VK Next под Tampermonkey и гарантируем корректную работу только в этом установщике скриптов. <br/><br/>Если Вы используете script-версию VK Next через другой установщик (Violent/MeddleMonkey и т. д.), мы не станем рассматривать ваши жалобы или баг-репорты.`,
			},
			{
				header: "В какие браузеры можно установить VK Next?",
				text: `Обе версии VK Next поддерживают популярные браузеры на основе Chromium и Firefox. В их числе Chrome, Opera, Microsoft Edge, Firefox, Cent, Vivaldi и другие. Браузер Safari не поддерживается ни одной из версий VK Next.`,
			},
			{
				header: "Как расширение обновляется?",
				text: `VK Next автоматически находит и предлагает новые версии. Отказаться от установки обновления нельзя.`,
			},
			{
				header: "Как часто выходят обновления?",
				text: `Как только, так сразу. Однако стоит отметить, что store-версия получает обновления позже script-версии, так как модерация в магазинах расширений занимает время.`,
			},
			{
				header: "Где публикуется информация о выходе обновлений?",
				children: [
					{
						type: "Text",
						text: "В нашем ",
					},
					{
						type: "Link",
						text: "Telegram-канале",
						href: "https://t.me/vknext",
					},
					{
						type: "Text",
						text: " выходят посты о выходе обновлений, а так же другая важная информация для пользователей расширения. Обязательно подпишитесь, это в Ваших интересах!",
					},
				],
			},
			{
				header: "Почему script-версия VK Next не поддерживает браузер Safari, не смотря на наличие Tampermonkey для него?",
				text: `Данный браузер используется малым количеством пользователей, имеет специфический движок, под который многое сложно оптимизировать, а также Tampermonkey для Safari распространяется платно. Советуем использовать браузеры на базе Chromium и Firefox.`,
			},
			{
				header: "Как удалить VK Next?",
				text: `Script-версия:
					<br/>
					<br/>
					Откройте меню расширения Tampermonkey (клик по иконке в панели расширений браузера), выберите "Панель управления", в открывшейся вкладке нажмите на иконку удаления у скрипта "VK Next", она находится справа.
					<br/>
					<br/>
					Store-версия:
					<br/>
					<br/>
					Нажмите правой кнопкой мыши на иконку VK Next в панели расширений браузера или перейдите в настройки расширений, нажмите кнопку "Удалить из браузера" и при необходимости подтвердите удаление.
				`,
			},
		],
	},
	settings: {
		name: "Взаимодействие с расширением",
		answers: [
			{
				ignore: true,
				header: "Я установил расширение. Где найти его настройки?",
				text: `Настройки VK Next находятся в правом меню шапки ВКонтакте:`,
				img: {
					src: `https://vknext.net/static/media/vkn_settings.png`,
				},
			},
			{
				header: "Как скачать какой-либо медиа-файл?/Где найти кнопку скачивания?",
				text: `С помощью VK Next Вы можете скачивать видео, клипы, музыку, плейлисты, фото, альбомы, истории и голосовые сообщения. Ниже Вы можете увидеть расположение кнопок скачивания.`,
				img: {
					src: `https://vknext.net/static/media/vkn_downloads.png`,
					width: "96%",
					height: "96%",
				},
			},
			{
				header: "Где найти кнопку распознавания текста голосового сообщения?",
				text: `Она находится под голосовым сообщением и имеет символ "Аа".`,
			},
			{
				header: `Почему не смотря на включённый частичный офлайн, я "онлайн"?`,
				text: `При любых действиях, включая отправку сообщений, переход в другой раздел, обновление страницы, в веб-версии отправляется статус онлайн. При использовании частичного офлайна, вы будете быстрее попадать в офлайн при относительном бездействии. По другому в веб-версии невозможно.`,
			},
			{
				header: "Можно ли VK Next использовать параллельно с другими расширениями для ВКонтакте?",
				text: `Мы не рекомендуем делать этого. Некоторые элементы интерфейса могут накладываться друг на друга, а также возможны более серьёзные конфликты и ошибки. Можете даже не пытаться репортить какие-либо баги при использовании других расширений для ВКонтакте параллельно с нашим, мы их не примем.`,
			},
			{
				header: "Почему у меня наблюдаются проблемы с VK Next в Яндекс.Браузере?",
				children: [
					{
						type: "Text",
						text: "Возможны 2 причины: вина расширения и вина браузера. Проверьте, наблюдается ли такое в других браузерах (например, Chrome, Firefox, Edge и т.д.), если да - обратитесь с проблемой в ",
					},
					{
						type: "Link",
						text: "чат поддержки VK Next",
						href: "https://t.me/vknext_support_bot",
					},
					{
						type: "Text",
						text: ", если же нет - виноваты криворукие разработчики Яндекс.Браузера и мы не в силах что-либо сделать.",
					},
				],
			},
		],
	},
	feedback: {
		name: "Связь с разработчиками",
		answers: [
			{
				header: "Я нашел баг/столкнулся с проблемой, куда мне сообщить об этом?",
				children: [
					{
						type: "Text",
						text: "Отчёты о багах в нашем ",
					},
					{
						type: "Link",
						text: "Баг-трекере",
						href: "https://vknext.net/bugs",
					},
					{
						type: "Text",
						text: `
							Раздел доступен как на сайте, так и в меню расширения. При составлении отчёта, пожалуйста, по существу описывайте проблему, если есть возможность, прикрепляйте скриншоты. Чем лучше составлен отчёт, тем быстрее мы сможем выявить и решить проблему.
							<br/>
					<br/>
							Однако, перед тем как составить отчёт, проверьте в разделе "Отчёты", не сообщали ли об этой проблеме ранее, чтобы не засорять баг-трекер дублированием. Если отчёт о вашей проблеме уже есть, Вы можете дополнить его в комментариях.
						`,
					},
				],
			},
			{
				header: "Где я могу узнать, что разработчики готовят к новому обновлению/что планируется сделать в будущем/над чем идет работа сейчас?",
				children: [
					{
						type: "Text",
						text: "Для этого у нас есть специальная доска с карточками планов на ",
					},
					{
						type: "Link",
						text: "Trello",
						href: "https://trello.com/b/B9Y5uB2h",
					},
				],
			},
			{
				header: `Почему, несмотря на наличие функций в колонке "Готово" на Trello, обновление не выходит?`,
				text: `В обновлении мы планируем определенный набор фич, и тот факт, что некоторые из них уже готовы, не означает, что готово всё обновление.<br/><br/>Также перед релизом обновление проходит стадию тестирования и исправления выявленных в ходе него проблем.`,
			},
			{
				header: "Я бы хотел предложить идею новой функции. Где я могу это сделать?",
				children: [
					{
						type: "Text",
						text: "Сделать это можно также на ",
					},
					{
						type: "Link",
						text: "Trello",
						href: "https://trello.com/b/B9Y5uB2h",
					},
					{
						type: "Text",
						text: ", в комментариях к ",
					},
					{
						type: "Link",
						text: "специальной карточке",
						href: "https://trello.com/c/gHVsLVs1",
					},
					{
						type: "Text",
						text: " или в ",
					},
					{
						type: "Link",
						text: "Баг-трекере",
						href: "http://vknext.net/bugs",
					},
					{
						type: "Text",
						text: `: при составлении отчёта поставьте ему тег "Пожелание".`,
					},
				],
			},
			{
				header: "Могу ли я связаться непосредственно с разработчиками VK Next?",
				children: [
					{
						type: "Text",
						text: "Разработчики часто отвечают в нашем ",
					},
					{
						type: "Link",
						text: "Telegram-чате",
						href: "http://t.me/vknext_chat",
					},
					{
						type: "Text",
						text: ". Однако, если Вы хотите побеседовать лично, можете сделать это в специальном ",
					},
					{
						type: "Link",
						text: " чате поддержки VK Next ",
						href: "http://t.me/vknext_support_bot",
					},
					{
						type: "Text",
						text: `(на вопросы по типу "Где *какая-либо функция* даже отвечать не станем").`,
					},
				],
			},
		],
	},
	privacy: {
		name: "Сбор данных и безопасность",
		answers: [
			{
				header: `Мою страницу могут заблокировать за использование VK Next?`,
				text: `Нет. ВКонтакте не блокируют аккаунты за использование браузерных расширений и модифицированных приложений. По крайней мере, о таких случаях, на сегодняшний день, неизвестно.`,
			},
			{
				header: "После установки расширения в истории входов в аккаунт появились неизвестные мне устройства. Вы меня взломали?",
				text: `Никакого взлома. Эти устройства отображаются там, так как расширение получило ключ авторизации вашего аккаунта, не содержащий никаких конфиденциальных данных, но необходимый для работы многих функций, например офлайн-режима, установки эмодзи-статусов и многого другого. <br/><br/>Мы не получаем никаких данных об аккаунте, а получение ключа происходит локально, в используемом браузере. Не волнуйтесь!`,
			},
			{
				header: `Почему мой антивирус "ругается" на script-версию VK Next?`,
				text: `Это происходит крайне редко, но если Вы с этим столкнулись, знайте - скрипт VK Next полностью безопасен. Некоторые антивирусы, по непонятной нам причине, могут ругаться на обфускацию, которая делает код нечитаемым с целью предотвращения его воровства, либо на некоторые ссылки в коде, которые ведут к файлам на нашем сервере (например, наборы эмодзи). Проигнорируйте/отклоните такое предупреждение антивируса.`,
			},
			{
				header: `Зачем расширению нужен доступ ко всем сайтам (установлен match на все сайты)? Оно же только для ВКонтакте`,
				text: `Это необходимо для правильной работы всех функций расширения, а в частности покраски мини-приложений и VK UI разделов. Они работают как отдельные сайты (в ВК они запускаются в iframe), поэтому расширению нужен доступ к ним. Не волнуйтесь за это, на других сайтах оно никак не запустится.`,
			},
			{
				header: `Для чего код расширения обфусцирован (зашифрован)?`,
				text: `Это сделано для того, чтобы придать коду нечитаемый вид, во избежание его заимствования и использования посторонними лицами. Мы не готовы делиться своим трудом со всем интернетом, поэтому функции, созданные нами, можно использовать только в нашем же расширении. 
					<br/>
					<br/>
					Других целей у данного явления нет, никакой вредоностный код мы таким образом не прячем, у нас его нет. Не верите? Это ваше право. Каждый сам решает, использовать VK Next или нет.`,
			},
		],
	},
	prime: {
		name: "",
		answers: [
			{
				header: "Я совершил перевод, но привилегия не выдана спустя более 10 минут. Почему?",
				children: [
					{
						type: "Text",
						text: `Возможно вы не указали комментарий/указали неправильный, сумма перевода ниже минимально допустимой или вы не обновили страницу. Если вы уверены в правильности данных, обновите страницу во вкладке с ВКонтакте через несколько минут.<br/><br/>Если же в течение 20 минут привилегия все еще не выдана - напишите в `,
					},
					{
						type: "Link",
						text: "чат поддержки VK Next",
						href: "http://t.me/vknext_support_bot",
					},
					{
						type: "Text",
						text: `, добавив скриншот полной информации о платеже и ссылку на страницу ВК, которую указали при оплате.<br/><br/>В случае допуска Вами ошибки при переводе (например, не указали комментарий), выдачи привилегии, как и возврата денежных средств не будет! Во избежание этого советуем использовать кнопку "Перейти к оплате", в этом случае комментарий будет подставлен автоматически.`,
					},
				],
			},
		],
	},
};

function createLDJson() {
	let _ = document.querySelector(
		`script#vknext_help[type="application/ld+json"]`,
	);
	if (_) _.remove();
	let arr = []; //help.install.answers.concat(help.feedback.answers).concat(help.extension.answers).concat(help.privacy.answers);
	Object.keys(help).forEach((el) => {
		arr = arr.concat(help[el].answers);
	});
	arr = arr.filter(function (el) {
		return el != null;
	});
	var FAQPage = {
		"@context": "https://schema.org",
		"@type": "FAQPage",
		mainEntity: arr
			.map((el) => {
				if (el.ignore) return null;
				let text = el.text;
				if (el.children) {
					text = el.children
						.map((t) => {
							switch (t.type) {
								case "Text":
									return `<p>${t.text}</p>`;
								case "Link":
									return `<a href=${t.href} target="_blank">${t.text}</a>`;
								default:
									return "";
							}
						})
						.join("");
				}
				return {
					"@type": "Question",
					name: el.header,
					acceptedAnswer: {
						"@type": "Answer",
						text: text,
					},
				};
			})
			.filter(function (el) {
				return el != null;
			}),
	};
	let script = document.createElement("script");
	script.id = "vknext_help";
	script.type = "application/ld+json";
	script.textContent = JSON.stringify(FAQPage);
	document.head.append(script);
}

const categories = Object.keys(help);

function currentCategory() {
	var cur = getQueryVariable("category");
	if (cur && categories.includes(cur)) return cur;
	return categories[0];
}

export function generateAnswers(name) {
	var curAnswer = help[name];
	if (!curAnswer) return false;
	curAnswer = curAnswer.answers;
	return (
		<>
			{Object.keys(curAnswer).map((el) => {
				el = curAnswer[el];
				var text = (
					<Text className="faqText">
						<div dangerouslySetInnerHTML={{ __html: el.text }} />
					</Text>
				);
				if (el.children) {
					text = (
						<Text className="faqText">
							{el.children.map((t) => {
								switch (t.type) {
									case "Text":
										return (
											<span
												dangerouslySetInnerHTML={{
													__html: t.text,
												}}
											></span>
										);
									case "Link":
										return (
											<Link href={t.href} target="_blank">
												{t.text}
											</Link>
										);
									default:
										return "";
								}
							})}
						</Text>
					);
				}
				return (
					<CardGrid size="l">
						<Card id="">
							<Div>
								<Header className="faqHeader">
									<div
										dangerouslySetInnerHTML={{
											__html: el.header,
										}}
									/>
								</Header>
								{text}
								{el.img ? (
									<div className="imgCenter">
										<img
											alt=""
											style={{
												width: el.img.width,
												height: el.img.height,
											}}
											src={el.img.src}
										/>
									</div>
								) : (
									""
								)}
							</Div>
						</Card>
					</CardGrid>
				);
			})}
		</>
	);
}

export default class PanelFaq extends Component {
	constructor(props) {
		super(props);
		this.hasHeaderPanel = props.hasHeaderPanel;
		this.app = props.app;
		this.isDesktop = props.isDesktop;
		this.state = {
			category: currentCategory(),
		};

		let temp = this;
		var originalFunc = this.setState;
		this.setState = function (t, a) {
			if (t.category) {
				window.history.pushState(
					null,
					null,
					`${window.location.pathname}?category=${t.category}`,
				);
			}
			originalFunc.apply(this, arguments);
			window.onpopstate = () => {
				temp.setState1({ category: currentCategory() });
				temp.app.setState1({
					panel: window.location.pathname.replace("/", ""),
				});
			};
		};
		this.setState1 = function () {
			originalFunc.apply(this, arguments);
		};
	}

	render() {
		createLDJson();
		if (this.state.category && help[this.state.category].name) {
			document.title = `VK Next | ${help[this.state.category].name}`;
		} else {
			document.title = "VK Next | Помощь";
		}
		//const cts1 = generateAnswers(categories[1];
		return (
			<View activePanel={this.state.category}>
				<Panel id={categories[0]}>
					{this.hasHeaderPanel && <PanelHeader>Помощь</PanelHeader>}
					<Group>
						<CardGrid size="l" style={{ marginBottom: 5 }}>
							<Card>
								<Div>
									<Text className="faqText">
										В этом разделе собраны ответы на часто
										задаваемые вопросы о VK Next.
										Настоятельно рекомендуем ознакомиться с
										ними!
									</Text>
									<br />
									<Text className="faqText">
										Чтобы нужный ответ было проще найти, они
										рассортированы по тематическим
										категориям.
									</Text>
								</Div>
							</Card>
						</CardGrid>
						<Cell
							before={<Icon28LaptopOutline />}
							description="Сколько версий у VK Next и в чём их отличия?"
							onClick={() =>
								this.setState({ category: categories[1] })
							}
						>
							Различия версий
						</Cell>
						<Cell
							before={<Icon28DownloadOutline />}
							description="Как обновляется? Что с Safari? Как удалить?"
							onClick={() =>
								this.setState({ category: categories[2] })
							}
						>
							Установка и обновления
						</Cell>
						<Cell
							before={<Icon28SettingsOutline />}
							description="Информация о настройке и использовании"
							onClick={() =>
								this.setState({ category: categories[3] })
							}
						>
							Взаимодействие с расширением
						</Cell>
						<Cell
							before={<Icon28QuestionOutline />}
							description="Куда сообщить о проблеме, предложить идею?"
							onClick={() =>
								this.setState({ category: categories[4] })
							}
						>
							Связь с разработчиками
						</Cell>
						<Cell
							before={<Icon28CheckShieldOutline />}
							description="Использовать VK Next безопасно для аккаунта?"
							onClick={() =>
								this.setState({ category: categories[5] })
							}
						>
							Сбор данных и безопасность
						</Cell>
						{!this.isDesktop && <div className="tabbar_fix"></div>}
					</Group>
				</Panel>
				<Panel id={categories[1]}>
					<PanelHeader
						left={
							<PanelHeaderBack
								onClick={() =>
									this.setState({ category: categories[0] })
								}
							/>
						}
					>
						Помощь
					</PanelHeader>
					<Group>
						{generateAnswers(categories[1])}
						{!this.isDesktop && <div className="tabbar_fix"></div>}
					</Group>
				</Panel>
				<Panel id={categories[2]}>
					<PanelHeader
						left={
							<PanelHeaderBack
								onClick={() =>
									this.setState({ category: categories[0] })
								}
							/>
						}
					>
						Помощь
					</PanelHeader>
					<Group>
						{generateAnswers(categories[2])}
						{!this.isDesktop && <div className="tabbar_fix"></div>}
					</Group>
				</Panel>
				<Panel id={categories[3]}>
					<PanelHeader
						left={
							<PanelHeaderBack
								onClick={() =>
									this.setState({ category: categories[0] })
								}
							/>
						}
					>
						Помощь
					</PanelHeader>
					<Group>
						{generateAnswers(categories[3])}
						{!this.isDesktop && <div className="tabbar_fix"></div>}
					</Group>
				</Panel>
				<Panel id={categories[4]}>
					<PanelHeader
						left={
							<PanelHeaderBack
								onClick={() =>
									this.setState({ category: categories[0] })
								}
							/>
						}
					>
						Помощь
					</PanelHeader>
					<Group>
						{generateAnswers(categories[4])}
						{!this.isDesktop && <div className="tabbar_fix"></div>}
					</Group>
				</Panel>
				<Panel id={categories[5]}>
					<PanelHeader
						left={
							<PanelHeaderBack
								onClick={() =>
									this.setState({ category: categories[0] })
								}
							/>
						}
					>
						Помощь
					</PanelHeader>
					<Group>
						{generateAnswers(categories[5])}
						{!this.isDesktop && <div className="tabbar_fix"></div>}
					</Group>
				</Panel>
			</View>
		);
	}
}
