import {
	Group,
	Header,
	SimpleCell,
	Banner,
	Link,
} from '@vkontakte/vkui';
import React, { Component }  from 'react';

import { getQueryVariable } from '../utils';
import PanelLinks from './PanelLinks';

import { Icon12OnlineVkmobile } from '@vkontakte/icons';

export default function PanelAbout({app, isDesktop}) {
	document.title = "ВТостерс | About";
	
	var IFRAME = getQueryVariable('iframe') === 'true';
//badge={<Icon12OnlineVkmobile/>}
	return (
		<Group>
			<PanelLinks
				mode="plain"
				app={app}
				isDesktop={true}
				header={<Header mode="secondary">Ссылки</Header>}
				IFRAME={IFRAME}
			/>
			<Group
				header={<Header mode="secondary">Команда</Header>}
				mode="plain"
			>
				<Banner
					mode="image"
					size="m"
					header={<b>Внимание!</b>}
					subheader={
						<div>
							<span>ЛС НЕ предназначен для багов/крашей и
										предложений/просьб сделать новые фичи, такие сообщения будут
										<b> игнорироваться. </b></span>
							<Link
								href="https://t.me/vtosterschat"
								target="_blank"
								style={{
									color: '#9ecbff'
								}}
							>Воспользуйтесь чатом в телеграме!</Link>
							
							<br/>
						</div>
					}
					background={
						<div
							style={{
								background: 'brown',
							}}
						/>
					}
				/>
				<div className="vknuiGrid">
					<SimpleCell 
						description="Основатель модификации и разработчик VTosters, VTLite"
						onClick={()=>window.open('https://t.me/gdlbo')}
						badge={<Icon12OnlineVkmobile/>}
					>
						gdlbo
					</SimpleCell>
					<SimpleCell 
						description="Один из главных разработчиков VTLite, а также любитель работать по 70 часов в неделю"
						onClick={()=>window.open('https://t.me/toxyxd')}
						badge={<Icon12OnlineVkmobile/>}
					>
						aliveoutside
					</SimpleCell>
					<SimpleCell 
						description="Один из главных разработчиков VTLite"
						onClick={()=>window.open('https://t.me/vologhat')}
						badge={<Icon12OnlineVkmobile/>}
					>
						Vologhat
					</SimpleCell>
					
					<SimpleCell 
						description="Маляр и новые функции"
						onClick={()=>window.open('https://t.me/itaysonlab')}
						badge={<Icon12OnlineVkmobile/>}
					>
						iTaysonLab
					</SimpleCell>

					<SimpleCell 
						description="Помощник и новые функции"
						onClick={()=>window.open('https://github.com/features/copilot')}
						badge={<Icon12OnlineVkmobile/>}
					>
						Copilot
					</SimpleCell>

					<SimpleCell 
						description="Большое спасибо нашим контрибьютерам за помощь в разработке"
						onClick={()=>window.open('https://github.com/vtosters/lite/graphs/contributors')}
						badge={<Icon12OnlineVkmobile/>}
					>
						Контрибьютеры
					</SimpleCell>
					
					<SimpleCell 
						description="Основатель и разработчик VTMessenger, а также этого окна"
						onClick={()=>window.open('https://t.me/kristian5336')}
						badge={<Icon12OnlineVkmobile/>}
					>
						Kr3st1k
					</SimpleCell>

					<SimpleCell 
						description="Всесторонняя помощь в разработке"
						onClick={()=>window.open('https://t.me/polychromaticfox')}
					>
						polychromaticfox
					</SimpleCell>
				</div>
			</Group>
			<Group
				header={<Header mode="secondary">Большое спасибо</Header>}
				mode="plain"
			>
				<div className="vknuiGrid">
				    <SimpleCell 
						description="Помощь с дизайном, логотипом и не только"
						onClick={()=>window.open('https://t.me/the8055u')}
					>
						the8055u
					</SimpleCell>
					<SimpleCell 
						description="Разработчик Sova V, небольшие советы и моральная поддержка"
						onClick={()=>window.open('https://t.me/ytkab0bp')}
					>
						YTKAB0BP
					</SimpleCell>
					<SimpleCell 
						description="Бывший разработчик VTosters"
						
					>
						ubdjshdb
					</SimpleCell>
					<SimpleCell 
						description="Бывший разработчик VTosters"
						onClick={()=>window.open('https://t.me/misha105')}
					>
						Misha105
					</SimpleCell>
					
					<SimpleCell 
						description="Бывший разработчик VTosters"
					>
						keimoger
					</SimpleCell>
					<SimpleCell 
						description="Бывший разработчик VTosters"
						onClick={()=>window.open('https://t.me/f0x1d')}
					>
						F0x1d
					</SimpleCell>
					<SimpleCell 
						description="Бывший разработчик VTMessenger"
					>
						teidesu
					</SimpleCell>
					<SimpleCell 
						description="Дизайнер иконок расширенных настроек VTosters"
					>
						DriffeX
					</SimpleCell>
					<SimpleCell 
						description="Логотип VTosters и помощь с иконками"
						onClick={()=>window.open('https://t.me/the_dise')}
					>
						the_dise
					</SimpleCell>
				</div>
			</Group>
			{!isDesktop && (<div className="tabbar_fix"></div>)}
		</Group>
	);
};