import {
	Panel,
	Group,
	View,
	Banner,
	Text,
	Button,
} from "@vkontakte/vkui";

import React, { Component } from "react";

// function getOTAstuff(ota){
// 	let flag1 = false;
// 	let flag2 = false;
// 	let flag3 = false;

// 	let URL = `https://${window.location.hostname}/vktoaster/ota?buildtype=0`;
// 	let xhr = new XMLHttpRequest();
// 	xhr.open("GET", URL);
// 	xhr.onload = function () {
// 		if (xhr.status === 200) {
// 			let data = JSON.parse(xhr.responseText);
// 			ota.vtmain =  {'link': data.link, 'v': data.v};	
// 		}
// 		flag1 = true;
// 	};
// 	xhr.send();

	
// 	URL = `https://${window.location.hostname}/vktoaster/ota?buildtype=100`;
// 	xhr = new XMLHttpRequest();
// 	xhr.open("GET", URL);
// 	xhr.onload = function () {
// 		if (xhr.status === 200) {
// 			let data = JSON.parse(xhr.responseText);
// 			ota.vthuawei = {'link': data.link, 'v': data.v};
// 		}
// 		flag2 = true;
// 	};
// 	xhr.send();

// 	URL = `https://${window.location.hostname}/vktoaster/ota?buildtype=102`;
// 	xhr = new XMLHttpRequest();
// 	xhr.open("GET", URL);
// 	xhr.onload = function () {
// 		if (xhr.status === 200) {
// 			let data = JSON.parse(xhr.responseText);
// 			ota.microg = {'link': data.link, 'v': data.v} ;
// 		};
// 		flag3 = true;
// 		}
	
// 	xhr.send();
	
// 	return ota;
// }

export default function PanelHome({ app, isDesktop }) {
	document.title = "ВТостерс";
	var ota = {'vtmain': {'link': 'https://cdn.discordapp.com/attachments/619845040268640257/813029291939070002/6.13.2_19.6.3.apk', 'v': ''}, 'vthuawei': {'link': 'https://cdn.discordapp.com/attachments/619845040268640257/813029458449137694/6.13.2_19.6.3_h.apk', 'v': ''}, 'microg': {'link': 'https://cdn.discordapp.com/attachments/704417982180491327/826341679955050546/0.2.18.210390.apk', 'v': ''}}
	return (
		
		<Group>
			<View activePanel={app.state.activeTab}>
			<Panel id="info">
                    <div>
					<div>
					<Banner
						mode="image"
						header="VTLite"
						subheader={
							<Text>
								Очень быстрая и функциональная модификация официального приложения ВКонтакте<br/><br/>
								Преемник старому <b>VTosters</b> который больше <b>не поддерживается</b>
							</Text>
						}
						background={
							<div
								style={{
									backgroundImage:
										"linear-gradient(139deg, rgb(91 132 247 / 95%) 0%, rgb(133 133 133) 100%)",
								}}
							/>
						}
						actions={<><Button mode="primary" style={{margin: "8px 0px 12px 0px", width:"fit-content", background: "#e1e3e6", color: "#19191a"}} href={"https://github.com/vtosters/lite/releases/latest/download/VTLite.apk"} size="m">Скачать</Button><br/><Button mode="primary" style={{margin: "0px 0px 12px 0px", width:"fit-content", background: "#e1e3e6", color: "#19191a"}} href={"https://github.com/vtosters/lite/"} size="m">Посмотреть исходный код</Button></>}
						style={{ padding: "0 8px" }}
					/>
					</div>
					<div>
					<Banner
						mode="image"
						header="VTMessenger"
						subheader={
							<Text>
								Модификация официального мессенджера VK для компьютера
							</Text>
						}
						background={
							<div
								style={{
									backgroundImage:
										"linear-gradient(135deg, rgb(181 130 193) 0%, rgb(136, 146, 244) 100%)",
								}}
							/>
						}
						actions={<Button mode="primary" style={{margin: "8px 0px 12px 0px", width:"fit-content", background: "#e1e3e6", color: "#19191a"}} href="https://t.me/s/vtmsg" size="m">Скачать</Button>}
						style={{ padding: "0 8px" }}
					/>
					</div>
					<div>
					<Banner
						mode="image"
						header="Фильтр для Adblock"
						subheader={
							<Text>
								Совместим только с <b>браузерным ВКонтакте</b><br />Поддерживается <b>мобильной веб версией ВКонтакте</b><br /><br /><b>Блокирует</b>:<br />
                                            • <b>Рекламу</b><br />
											• <b>Рекламу групп</b><br />
											• <b>Бесполезные рекомендации друзей и авторов</b><br />
											• <b>Слежку и аналитику</b><br />
											• <b>Посты с источником и пометкой рекламы</b><br />
											<br />
											
											Рекомендуется установка в <b>ADGuard</b>.<br />
											Также возможна установка в <b>UBlock</b>, <b>AdBlock Plus</b>. Работа в других расширениях <b>не гарантируется</b>.
							</Text>
						}
						background={
							<div
								style={{
									backgroundImage:
										"linear-gradient(135deg, rgb(154 136 207) 0%, #de6161 100%)",
								}}
							/>
						}
						actions={<><Button mode="primary" style={{margin: "8px 0px 12px 0px", width:"fit-content", background: "#e1e3e6", color: "#19191a"}} href="abp:subscribe?location=https://vtosters.app/adblock.txt" size="m">Установить</Button><br/><Button mode="primary" style={{margin: "0px 0px 12px 0px", width:"fit-content", background: "#e1e3e6", color: "#19191a"}} href="https://github.com/vtosters/adblock" size="m">Посмотреть исходный код</Button></>}
						style={{ padding: "0 8px" }}
					/>
					</div>
					<div>
					<Banner
						mode="image"
						header="VK Next"
						subheader={
							<Text>
								Обширное расширение для веб версии ВКонтакте
							</Text>
						}
						background={
							<div
								style={{
									backgroundImage:
										"linear-gradient(135deg, rgb(232, 104, 18) 0%, rgb(183, 33, 255) 100%)",
								}}
							/>
						}
						actions={<Button mode="primary" style={{margin: "8px 0px 12px 0px", width:"fit-content", background: "#e1e3e6", color: "#19191a"}} href="https://vknext.net" size="m">Перейти</Button>}
						style={{ padding: "0 8px" }}
					/>
					</div>
					<div>
					<Banner
						mode="image"
						header="VK X"
						subheader={
							<Text>
								Многофункциональный плеер музыки для ВКонтакте без рекламы с интеграцией ВТостерса
							</Text>
						}
						background={
							<div
								style={{
									backgroundImage:
										"linear-gradient(157deg, rgb(232 61 255) 0%, rgb(147 61 61) 100%)",
								}}
							/>
						}
						actions={<Button mode="primary" style={{margin: "8px 0px 12px 0px", width:"fit-content", background: "#e1e3e6", color: "#19191a"}} href="https://vkx.app" size="m">Перейти</Button>}
						style={{ padding: "0 8px" }}
					/>
					</div>
					<div>
					<Banner
						mode="image"
						header="Laney"
						subheader={
							<Text>
								Нативный мессенджер ВКонтакте для Windows 10 и 11
							</Text>
						}
						background={
							<div
								style={{
									backgroundImage:
										"linear-gradient(147deg, rgba(113, 179, 255, 0.73) 0%, rgb(46 46 64) 100%)",
								}}
							/>
						}
						actions={<><Button mode="primary" style={{margin: "8px 0px 12px 0px", width:"fit-content", background: "#e1e3e6", color: "#19191a"}} href={"https://elorucov.github.io/laney"} size="m">Перейти</Button><br/></>}
						style={{ padding: "0 8px" }}
					/>
					</div>
                </div>
				</Panel>
			</View>
			{!isDesktop && <div className="tabbar_fix"></div>}
		</Group>
	);
}
