import React, { Component } from "react";
import axios from 'axios';
import {
	Panel,
	Group,
	Tabs,
	TabsItem,
	View,
	Banner,
	CardGrid,
	Card,
	Div,
	Text,
	Caption,
	Button, SimpleCell, List,
} from "@vkontakte/vkui";

import PanelDonate from './PanelDonate';
import {
	Icon12FireVerified,
	Icon28Like,
} from "@vkontakte/icons";

export default class PanelDonsite extends Component {
	constructor(props) {
		super(props);

		this.state = {
			comment: null,
			currentPay: null,
			currentPrime: null,
			account: null,
			gettingForm: false,
			galo4ki: null,
			getCensoredId: function (num)
			{
				let res = num.toString();
				return res.slice(0,res.length-2) + "**";
			}
		};
	}

	componentDidMount() {
		axios
			.get("https://gdlbo.net/vtapi/galo4ki")
			.then((res) => {
				this.setState({ galo4ki: res.data })
			})
	}

	render() {
		const { app, isDesktop } = this.props;

		document.title = "ВТостерс | Пожертвование";
		return (
			<Group>
				<Card style={{marginTop: "9px"}}>
					<Text>Пожертвования больше не принимаются</Text>
				</Card>
				{!isDesktop && <div className="tabbar_fix"></div>}
			</Group>
		);
	}
}
