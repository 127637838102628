import React from "react";
import ReactDOM from "react-dom";
import VTSite from "./VTSite";
import { Scheme } from "@vkontakte/vkui";
import "@vkontakte/vkui/dist/vkui.css";
import "./index.css";
// eslint-disable-next-line
Array.prototype.last = function () {
	return this[this.length - 1];
};

if (window.matchMedia("(prefers-color-scheme)").media !== "not all") {
	window.matchMedia("(prefers-color-scheme: dark)").addListener((e) => {
		document.body.setAttribute(
			"scheme",
			`${e.matches ? Scheme.SPACE_GRAY : Scheme.BRIGHT_LIGHT}`,
		);
		document.querySelector("meta[name=theme-color]").setAttribute("content", e.matches ? '#19191a' : '#ffffff');
	});
}

ReactDOM.render(<VTSite />, document.getElementById("root"));
