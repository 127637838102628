import { Scheme } from "@vkontakte/vkui";
import { toArray } from "react-emoji-render";
import React, { Component }  from 'react';

const panels = [
	"/",
	"donate",
	"--",
	"=--",
	"-=-",
	"about",
	"--=",
];
const tabs = {
	home: ["info", "install"],
	donate: ["info", "pay"],
};

function currentTab(def = "info") {
	let panel = currentPanel();
	let tab = getQueryVariable("tab");
	if (tab && tabs[panel] && tabs[panel].includes(tab)) return tab;
	return def;
}

function currentPanel() {
	let splitted = window.location.pathname.split("/");
	splitted.pop();
	let path = splitted.last();
	if (panels.includes(path)) return path;
	return panels[0];
}

function getQueryVariable(variable, hash = false) {
	var query = window.location[hash ? "hash" : "search"].substring(1);
	var vars = query.split("&");
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split("=");
		if (window.decodeURIComponent(pair[0]) === variable) {
			return window.decodeURIComponent(pair[1]);
		}
	}
}

function currentScheme() {
	let query = getQueryVariable("scheme");
	if (query === "amoled") return "amoled";
	if (query === "dark") return Scheme.SPACE_GRAY;
	if (query === "") return Scheme.BRIGHT_LIGHT;
	if (window.matchMedia("(prefers-color-scheme)").media !== "not all") {
		if (window.matchMedia("(prefers-color-scheme: dark)").matches)
			return Scheme.SPACE_GRAY;
		return Scheme.BRIGHT_LIGHT;
	}
	return Scheme.BRIGHT_LIGHT;
}

function getPlatform() {
	if (
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
			window.navigator.userAgent,
		)
	) {
		return "phone";
	} else return "computer";
}

function copy(text) {
	let dummy = document.createElement("textarea");
	document.body.appendChild(dummy);
	dummy.value = text;
	dummy.select();
	document.execCommand("copy");
	document.body.removeChild(dummy);
}

function inIframe() {
	try {
		return window.self !== window.top;
	} catch (e) {
		return true;
	}
}

function pluralFrom(n, v, need_n = true, need_cases = true) {
	return `${need_n ? n : ""} ${
		need_cases
			? v[
					4 < n % 100 && n % 100 < 20
						? 2
						: [2, 0, 1, 1, 1, 2][Math.min(n % 10, 5)]
			  ]
			: ""
	}`.trim();
}

function zeroStart(n) {
	return n.toString().length === 1 ? "0" + n : n;
}
function getMonth(m) {
	return [
		"янв",
		"фев",
		"мар",
		"апр",
		"мая",
		"июн",
		"июл",
		"авг",
		"сен",
		"окт",
		"ноя",
		"дек",
	][m];
}

function parseDate(t) {
	let d = new Date(t * 1e3),
		e = new Date();
	if (e.getTime() - d.getTime() < 6e4) {
		return "только что";
	}
	if (e.getTime() - d.getTime() < 6e4 * 2) {
		return "минуту назад";
	}
	if (e.getTime() - d.getTime() < 6e4 * 4) {
		return `${
			["две", "три"][parseInt((e.getTime() - d.getTime()) / 6e4) - 2]
		} ${pluralFrom(
			parseInt((e.getTime() - d.getTime()) / 6e4),
			["минуту", "минуты", "минут"],
			false,
		)} назад`;
	}
	if (e.getTime() - d.getTime() < 366e4) {
		return `${pluralFrom(parseInt((e.getTime() - d.getTime()) / 6e4), [
			"минуту",
			"минуты",
			"минут",
		])} назад`;
	}
	if (e.getTime() - d.getTime() < 36e5 * 2) {
		return `час назад`;
	}
	if (e.getTime() - d.getTime() < 36e5 * 4) {
		return `${
			["два", "три"][parseInt((e.getTime() - d.getTime()) / 36e5) - 2]
		} ${pluralFrom(
			parseInt((e.getTime() - d.getTime()) / 36e5),
			["час", "часа", "часов"],
			false,
		)} назад`;
	}
	if (e.getFullYear() !== d.getFullYear()) {
		return `${d.getDate()} ${getMonth(
			d.getMonth(),
		)} ${d.getFullYear()}г. в ${d.getHours()}:${zeroStart(d.getMinutes())}`;
	}
	if (e.getMonth() !== d.getMonth()) {
		return `${d.getDate()} ${getMonth(
			d.getMonth(),
		)} в ${d.getHours()}:${zeroStart(d.getMinutes())}`;
	}
	if (e.getDate() === d.getDate()) {
		return `сегодня в ${d.getHours()}:${zeroStart(d.getMinutes())}`;
	}
	if (e.getDate() - d.getDate() === 1) {
		return `вчера в ${d.getHours()}:${zeroStart(d.getMinutes())}`;
	}
	return `${d.getDate()} ${getMonth(
		d.getMonth(),
	)} в ${d.getHours()}:${zeroStart(d.getMinutes())}`;
}

const TAGS = {
	accent: "Акценты",
	appearance: "Внешний вид",
	rounding: "Закругления",
	errorText: "Ошибка в тексте",
	tgStickers: "Telegram-стикеры",
	hotKeys: "Горячие клавиши",
	galo4ki: "Галочки",
	bug: "Баг",
	wish: "Пожелание",
	bugTracker: "Баг-трекер",
	spying: "Шпионаж",
	rename: "Переименование",
	sideBar: "Левое меню",
	tgEmoji: "Анимированные эмодзи",
};

const BUGSTATUS = {
	open: "Открыт",
	closed: "Закрыт",
	rejected: "Отклонён",
	postponed: "Отложен",
	inWork: "В работе",
	readyForTest: "Готов к тестированию",
	wontFixed: "Не будет исправлено",
	irrelevant: "Неактуален",
	notReproduced: "Не воспроизводится",
	reopen: "Переоткрыт",
	fixed: "Исправлен",
	requiresCorrection: "Требует корректировки",
};

const PRIORITY = {
	low: "Низкий",
	average: "Средний",
	high: "Высокий",
	critical: "Критический",
};

const BRANCH = {
	all: "Любая",
	release: "Release",
	store: "Store",
	beta: "Beta",
	dev: "Dev",
};

function getUniqueArray(arr) {
	var i = 0,
		current,
		length = arr.length,
		unique = [];
	for (; i < length; i++) {
		current = arr[i];
		if (!~unique.indexOf(current)) {
			unique.push(current);
		}
	}
	return unique;
}

function getToken() {
	var token =
		getQueryVariable("token") ||
		getQueryVariable("access_token", true) ||
		localStorage.getItem("VKNBugsToken");
	window.location.hash = window.location.hash.replace(
		/access_token=(.+?)&/,
		"",
	);
	if (token) {
		localStorage.setItem("VKNBugsToken", token);
	}
	return token || false;
}

function deleteQueryStringParameters(name = []) {
	const params = new URLSearchParams(window.location.search);
	name.forEach((el) => {
		params.delete(el);
	});
	window.history.pushState(
		{},
		"",
		decodeURIComponent(`${window.location.pathname}?${params}`),
	);
}

function setQueryStringParameters(values = []) {
	const params = new URLSearchParams(window.location.search);
	values.forEach(({ name, value }) => {
		params.set(name, value);
	});
	window.history.pushState(
		{},
		"",
		decodeURIComponent(`${window.location.pathname}?${params}`),
	);
}

function strToUtf8Bytes(str) {
	const utf8 = [];
	for (let ii = 0; ii < str.length; ii++) {
		let charCode = str.charCodeAt(ii);
		if (charCode < 0x80) utf8.push(charCode);
		else if (charCode < 0x800) {
			utf8.push(0xc0 | (charCode >> 6), 0x80 | (charCode & 0x3f));
		} else if (charCode < 0xd800 || charCode >= 0xe000) {
			utf8.push(
				0xe0 | (charCode >> 12),
				0x80 | ((charCode >> 6) & 0x3f),
				0x80 | (charCode & 0x3f),
			);
		} else {
			ii++;
			// Surrogate pair:
			// UTF-16 encodes 0x10000-0x10FFFF by subtracting 0x10000 and
			// splitting the 20 bits of 0x0-0xFFFFF into two halves
			charCode =
				0x10000 +
				(((charCode & 0x3ff) << 10) | (str.charCodeAt(ii) & 0x3ff));
			utf8.push(
				0xf0 | (charCode >> 18),
				0x80 | ((charCode >> 12) & 0x3f),
				0x80 | ((charCode >> 6) & 0x3f),
				0x80 | (charCode & 0x3f),
			);
		}
	}
	return utf8;
}

function EmojiApple({ children, ...rest }) {
	var emojisArray = toArray(rest.text);

	emojisArray = emojisArray.map((el, index) => {
		if (el.props) {
			let emoji = el.props.children,
				bytes = strToUtf8Bytes(emoji)
					.map((i) => i.toString(16))
					.join("");
			return (
				<img
					key={`Emoji${emoji}_${bytes}_${index}`}
					className="emoji"
					src={`https://vknext.net/static/emoji/apple/${bytes}.png`}
					alt={emoji}
				></img>
			);
		}

		return el;
	});

	return emojisArray;
}

function isValidUrl(string) {
	let url;

	try {
		url = new URL(string);
	} catch (_) {
		return false;
	}

	return url.protocol === "http:" || url.protocol === "https:";
}

function addFunctionListener(obj, name, cb, after) {
	if (typeof obj[name] !== "function") {
		console.warn([obj, name, obj[name]]);
		return console.error(`VKNext FunctionListener: function not function`);
	}
	if (!obj[name + "_data"]) {
		obj[name + "_data"] = {
			function: obj[name],
			before: [],
			after: [],
		};
		obj[name] = function () {
			var data = {
				a: arguments,
				t: this,
				n: name,
				exit: 0,
			};
			obj[name + "_data"].before.forEach(function (f) {
				var res = f(data);
				if (res) {
					data = res;
				}
			});
			if (data.exit) {
				return data.exit;
			}
			data.exit = obj[name + "_data"].function.apply(data.t, data.a);
			obj[name + "_data"].after.forEach(function (f) {
				var res = f(data);
				if (res) {
					data = res;
				}
			});
			return data.exit;
		};
	}
	var id = obj[name + "_data"][after ? "after" : "before"].push(cb);
	return {
		remove: function () {
			obj[name + "_data"][after ? "after" : "before"].splice(id - 1, 1);
		},
		id: id,
	};
}

function waitCustomVar(obj, id, callback = (k, v) => {}, a = 0) {
	return new Promise(async (resolve) => {
		let _ = obj[id];
		if (!!_) {
			resolve(_);
			return callback(id, _);
		}
		if (a > 400) {
			throw new Error(`${id} not found`);
		}
		await delay(500);
		return resolve(await waitCustomVar(obj, id, callback, a + 1));
	});
}

function delay(ms) {
	new Promise((resolve) => setTimeout(resolve, ms));
}

function randomInteger(min, max) {
	let rand = min + Math.random() * (max + 1 - min);
	return Math.floor(rand);
}

export {
	getQueryVariable,
	currentScheme,
	getPlatform,
	currentTab,
	currentPanel,
	panels,
	tabs,
	copy,
	inIframe,
	pluralFrom,
	parseDate,
	zeroStart,
	getMonth,
	TAGS,
	BUGSTATUS,
	PRIORITY,
	BRANCH,
	getUniqueArray,
	getToken,
	deleteQueryStringParameters,
	setQueryStringParameters,
	EmojiApple,
	isValidUrl,
	addFunctionListener,
	waitCustomVar,
	randomInteger,
};
