import {
	Group,
	Cell,
} from '@vkontakte/vkui';

import {
	Icon28ChatsOutline,
	Icon28GlobeOutline,
	Icon28HelpCircleOutline
} from '@vkontakte/icons';
import React, { Component }  from 'react';

import {ReactComponent as Icon28TelegramOutline} from '../Icons/telegram_outline_28.svg';


export default function PanelLinks({app, isDesktop, mode, header, IFRAME}) {
	document.title = "ВТостерс | Ссылки";
	/*<Cell
				before={<Icon28MoneyCircleOutline/>}
				onClick={panel => app.setState({ panel: panels[5] })}
				description="Все деньги идут на развитие проекта"
			>
				Поддержать нас
			</Cell>
			<Spacing separator/> */
	return (
		<Group
			mode={mode}
			header={header}
		>
			
			<Cell
				before={<Icon28TelegramOutline/>}
				onClick={() => window.open('https://t.me/s/vtosters')}
				description="Новости и многое другое"
			>
				Telegram-канал ВТостерс
			</Cell>
			<Cell
				before={<Icon28ChatsOutline/>}
				onClick={() => window.open('https://t.me/vtosterschat')}
				description="Обсуждение постов и просто общение"
			>
				Чат ВТостерс в Telegram
			</Cell>
			<Cell
				before={<Icon28HelpCircleOutline/>}
				onClick={() => window.open('https://t.me/s/vtosters_faq')}
				description="Коротко и понятно о часто задаваемых вопросах"
			>
				ВТостерс FAQ
			</Cell>
			{IFRAME && (<Cell
				before={<Icon28GlobeOutline/>}
				onClick={() => window.open('https://vtosters.app')}
				description="Информация и другое"
			>
				Официальный сайт ВТостерс
			</Cell>)}
			
			{!isDesktop && (<div className="tabbar_fix"></div>)}
		</Group>
	);
};